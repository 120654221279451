document.addEventListener("DOMContentLoaded", function () {

  // Dodajemo event listener na "weiter lesen" link
  document.getElementById("showMoreLink").addEventListener("click", function (e) {
    e.preventDefault(); // Spriječavamo da se link preusmjeri na drugu stranicu

    var hiddenText = document.querySelector(".hide");
    var showMoreLink = document.getElementById("showMoreLink");

    if (hiddenText.style.display === "none" || hiddenText.style.display === "") {
      // Ako je tekst sakriven, prikažemo ga
      hiddenText.style.display = "block";
      showMoreLink.textContent = "Lese weniger"; // Promijenite tekst linka na "manje lesen"
    } else {
      // Inače, sakrijemo tekst
      hiddenText.style.display = "none";
      showMoreLink.textContent = "Weiterlesen"; // Vratimo tekst linka na "weiter lesen"
    }
  });

});

(function ($) {
  $(function () {


    // block_ausflugsziele 
    // Funkcija za obradu klika na element sa klasom "title"
    var titleElements = document.querySelectorAll('.col-lg-4 .title');

    titleElements.forEach(function(titleElement) {
      titleElement.addEventListener('click', function() {
        var parent = titleElement.parentElement;
        var info = parent.querySelector('.info');
        var icon = titleElement.querySelector('i');
  
        // Zatvorite sve prethodne "info" divove i postavite ikonice na "icon-angle-down"
        var allInfoElements = document.querySelectorAll('.info');
        var allTitleIcons = document.querySelectorAll('.col-lg-4 .title i');
        allInfoElements.forEach(function(otherInfo) {
          if (otherInfo !== info) {
            otherInfo.style.display = 'none';
          }
        });
        allTitleIcons.forEach(function(otherIcon) {
          if (otherIcon !== icon) {
            otherIcon.className = 'icon-angle-down';
          }
        });
  
        // Promenite prikaz "info" diva na osnovu trenutnog stanja
        if (info.style.display === 'none' || info.style.display === '') {
          info.style.display = 'block';
          // Promenite ikonicu na "icon-angle-up" kada se prikaže "info" div
          icon.className = 'icon-angle-up';
        } else {
          info.style.display = 'none';
          // Vratite ikonicu na "icon-angle-down" kada se sakrije "info" div
          icon.className = 'icon-angle-down';
        }
      });
    });


    var date = new Date();
    date.setDate(date.getDate());
    
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    $('#anreisedatum, #abreisedatum, #form .start_date input, #form .end_date input, .wpcf7-form .start_date input, .wpcf7-form .end_date input').datepicker({
      startDate: date,
      format: 'dd.mm.yyyy'  
    });


    $('.tomorrow input').datepicker({
      startDate: tomorrow,
      format: 'dd.mm.yyyy'  
    });



    const menuItemsWithChildren = document.querySelectorAll(".menu-item-has-children");

    menuItemsWithChildren.forEach((menuItem) => {
      const subMenu = menuItem.querySelector(".sub-menu");

      menuItem.addEventListener("click", function (event) {
        // event.preventDefault(); 

        // Sakrij sve druge podmenije
        document.querySelectorAll(".sub-menu.show").forEach((subMenu) => {
          if (subMenu !== this.querySelector(".sub-menu")) {
            subMenu.classList.remove("show");
          }
        });

        // Toggle prikazivanje/skrivanje podmenija
        subMenu.classList.toggle("show");
      });
    });


    $('.nav-button').on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('active');
      $('.navigation-floating').toggleClass('opened');
      $('.overlay-black').addClass('active');

    });

    $('.nav-button.active').on('click', function () {
      $(this).removeClass('active');
      $('.navigation-floating.opened').removeClass('opened');
    });

    //Remove opend class for cart
    $('.nav-floating-btn').on('click', function () {
      $(this).removeClass('opened');
      $('.navigation-floating').removeClass('opened');
      $('.nav-button').removeClass('active');
      $('.overlay-black').removeClass('active');

      $('.navigation-bottom.mobile').toggleClass('hide');
    });

    $(document).mouseup(function (e) {
      var floatingCart = $(".navigation-floating.opened");

      // if the target of the click isn't the container nor a descendant of the container
      if (!floatingCart.is(e.target) && floatingCart.has(e.target).length === 0) {
        $('.navigation-floating').removeClass('opened');
        $('.overlay-black').removeClass('active');
      }

    });

    $('div.tabela-overlay').on('touchmove', function () {
      $('div.tabela-overlay').hide();
  });

    /*
     * Navbar fixed 
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top fadeInDown animated');
      } else {
        $('.site-header').removeClass('navbar-fixed-top fadeInDown animated');
      }
    });

    /*
     * Toggle burger menu on mobile
     */
    $('.menu-btn-burger').on('click', function () {
      $('#nav-icon').toggleClass('open-custom-menu');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /* 
     * Owl carousel
     */
    $('.owl-room-card').owlCarousel({
      margin: 40,
      items: 3,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4500,
      nav: true,
      dots: false,
      animateOut: 'fadeOut',
      navText: ["<i class='icon-angle-left'></i>", "<i class='icon-angle-right'></i>"],
      // navContainer: '#customNav',
      responsive: {
        0: {
          items: 1
        },
        700: {
          items: 2,
          margin: 0,
        },
        1200: {
          items: 3,
        }
      }
    });

    /*
     * Video modal 
     */
    $("body").on("click", "#play-video-modal", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });

    /*
     * Open modal
     */

    $('#open-modal').on('click', function (e) {
      e.preventDefault();
      $('#modal').modal('show');
    });

    /*
     * Youtube lazy loading
     */
    $('#play-video-btn').on('click', function (e) {
      e.preventDefault();
      $('#youtubeModal').modal('show');
    });

    $("#youtubeModal").on('hidden.bs.modal', function (e) {
      $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    });



    $('#play-video-btn').on('click', function () {
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    })

    window.onYouTubeIframeAPIReady = function () {
      var videoModules = document.querySelectorAll('.video');
      videoModules = Array.prototype.slice.call(videoModules);
      videoModules.forEach(initializeVideoModule);
    }

    function initializeVideoModule(videoModule) {
      var player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          onStateChange: function (event) {
            var isEnded = event.data === YT.PlayerState.ENDED;
            videoModule.classList[isEnded ? 'remove' : 'add']('playing');
            if (isEnded) {
              player.destroy();
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModule(videoModule);
            }
          }
        }
      });
    }

    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });

    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });



    /*
     * Mmenu light 
     */
    // var menu = new MmenuLight(
    //   document.querySelector('#my-menu'),
    //   'all'
    // );
    // if (menu) {
    //   var navigator = menu.navigation({
    //     // selectedClass: 'Selected',
    //     // slidingSubmenus: true,
    //     theme: 'light',
    //     title: 'Menü'
    //   });

    //   var drawer = menu.offcanvas({
    //     position: 'right'
    //   });

    //   //  Open the menu.
    //   document.querySelector('#nav-icon')
    //     .addEventListener('click', evnt => {
    //       evnt.preventDefault();
    //       drawer.open();
    //     });

    //   $(".mob-menu-close").click(function () {
    //     drawer.close();
    //   });
    // }



    // Pronađite sve elemente sa klasom "title"
    var titleElements = document.querySelectorAll('.title');

    // Dodajte događaj za klik na svaki element sa klasom "title"
    titleElements.forEach(function (titleElement) {
      titleElement.addEventListener('click', function () {
        toggleInfo(this);
      });
    });


    const toggleIcons = document.querySelectorAll(".toggle-icon");
    const deleteIcons = document.querySelectorAll(".delete-icon");

    toggleIcons.forEach((icon, index) => {
      icon.addEventListener("click", () => {
        const hideElement = document.querySelector(".row.align-items-center.hide:not(.show-icon)");

        if (hideElement) {
          hideElement.classList.add("show-icon");
          icon.textContent = "+";
        }
      });
    });

    deleteIcons.forEach((icon, index) => {
      icon.addEventListener("click", () => {
        const parentRow = icon.parentElement.parentElement.parentElement;
        parentRow.classList.remove('show-icon');
      });
    });

  });
})(jQuery);